<template>
	<div class="table">
		<div class="crumbs">
		    <el-breadcrumb separator="/">
		        <el-breadcrumb-item><i class="el-icon-lx-cascades"></i> 调查用卷</el-breadcrumb-item>
		    </el-breadcrumb>
			<div class="container">
				<div class="handle-box">
				    <el-input v-model="title" placeholder="筛选关键词" class="handle-input mr10"></el-input>
					 <el-button type="primary" icon="search" @click="getData(1)" style="margin-left:10px;">搜索</el-button>
					 <el-button type="primary" style="float: right" @click="handleEdit(undefined, undefined, 1)">添加问卷</el-button>
					 <el-button type="primary" style="float: right" @click="handleQuestionEdit(undefined, undefined, 1)">添加题目</el-button>
					 <el-button type="primary"  @click="getQuestionData()">试题列表</el-button>
					<el-button type="primary" @click="getUserData()">参与用户</el-button>
				</div>
				<el-table :data="data" border class="table" ref="multipleTable">
					<!-- <el-table-column type="selection" width="55" align="center"></el-table-column> -->
					<el-table-column prop="id" label="ID"  align="center"></el-table-column>
					<el-table-column prop="title" label="问卷标题"  align="center"></el-table-column>
					<el-table-column prop="status" label="状态"  align="center"> 
						<template slot-scope='scope'>
						   <span v-if="scope.row.status < 1">禁用</span>
						   <span v-if="scope.row.status == 1">启用</span>
					    </template>
					</el-table-column>
					<el-table-column prop="create_time" label="创建时间"  align="center"></el-table-column>
					<el-table-column label="操作" width="150" align="center">
					    <template slot-scope="scope">
					        <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row, 2)">编辑</el-button>
					        <el-button type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
					    </template>
					</el-table-column>
				</el-table>
				<div class="pagination">
				    <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next" :total="sumPage">
				    </el-pagination>
				</div>
			</div>	
			<!-- 添加问卷 -->
			<el-dialog title="编辑" v-loading="loading" :visible.sync="editVisible" width="40%">
				 <el-form ref="form" :rules="rules" :model="form" label-width="145px">
					<el-form-item label="问卷标题" prop="title">
						<el-input v-model="form.title" placeholder="请输入问卷标题"></el-input>
					</el-form-item>
					<el-form-item label="状态" prop="status">
						<el-radio v-model="form.status"  label="0">禁用</el-radio>
						<el-radio v-model="form.status"  label="1">启用</el-radio>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
			        <el-button @click="editVisible = false">取 消</el-button>
			        <el-button type="primary" @click="saveEdit('form')">确 定</el-button>
			    </span>
			</el-dialog>
			
			<!-- 参与用户 -->
			<el-dialog title="参与用户" v-loading="loading" :visible.sync="userVisible" >
				<el-table :data="userTableData" border class="table">
				   <el-table-column prop="id" label="编号"  align="center" sortable></el-table-column>
				   <el-table-column prop="nickname" label="用户"  align="center" ></el-table-column>
					<el-table-column prop="avatar_url" label="头像"align="center"	 >
						<template slot-scope="scope">
							<img v-if="scope.row.avatarurl" :src=scope.row.avatarurl style="width:50px;height:50px;" />
						</template>
					</el-table-column>
					<el-table-column prop="survey_title" label="问卷"  align="center" ></el-table-column>
					<el-table-column prop="create_time" label="提交时间"  align="center" ></el-table-column>
					<el-table-column label="操作" align="center">
					    <template slot-scope="scope">
					        <el-button type="text" icon="el-icon-edit" @click="handleShow(scope.row)">查看详情</el-button>
					    </template>
					</el-table-column>
				</el-table>
				<div class="pagination">
				    <el-pagination background @current-change="handleCurrentChangeUser" layout="prev, pager, next" :total="user_table_sum_page">
				    </el-pagination>
				</div>
			</el-dialog>
			
			<el-dialog title="问卷详情" v-loading='loading' :visible.sync="userDetailVisible">		
				<el-form  :model="questionForm" >
					<div v-for="(item,index) in userDetail" :key="index">
						<el-form-item :label="item.title">
							<div v-if="item.item_title" >
								<el-input v-model="item.item_title" placeholder="" :disabled="true"></el-input>
							</div>
							<div v-if="item.single_row_text">
								<el-input  v-model="item.single_row_text" placeholder="" :disabled="true"></el-input>
							</div>
							<div v-if="item.multi_row_text">
								<el-input type="textarea" v-model="item.multi_row_text" placeholder="" :disabled="true"></el-input>
							</div>
						</el-form-item>
					</div>
				</el-form>	
			</el-dialog>
			<!-- 试题列表 -->
			<el-dialog title="试题列表" v-loading="loading" :visible.sync="questionVisible" >
				<el-table :data="questionTableData" border class="table">
				   <!-- <el-table-column prop="id" label="编号" width="100" align="center" sortable></el-table-column> -->
				   <el-table-column prop="title" label="题目名称" width="180" align="center" ></el-table-column>
					<el-table-column prop="survey_title" label="问卷名称" width="100" align="center" ></el-table-column>
					<el-table-column prop="type" label="类型" width="100" align="center" >
						<template slot-scope = 'scope'>
							<span v-if="scope.row.type < 1">单选</span>
							<span v-else>多选</span>
						</template>
					</el-table-column>
					<el-table-column prop="complex" label="输入类型" width="100" align="center" >
						<template slot-scope = 'scope'>
							<span v-if="scope.row.status < 1">默认</span>
							<span v-else-if="scope.row.complex == 1">多行文本</span>
							<span v-else-if="scope.row.complex == 2">单行文本</span>
							<!-- <span v-else></span> -->
						</template>
					</el-table-column>
					<el-table-column prop="status" label="类型" width="100" align="center" >
						<template slot-scope='scope'>
							<span v-if="scope.row.status < 1">隐藏</span>
							<span v-else>显示</span>
						</template>
					</el-table-column>
					<el-table-column prop="sort" label="排序" width="100" align="center" sortable></el-table-column>
					<el-table-column prop="create_time" label="创建时间" width="180" align="center" ></el-table-column>
					<el-table-column label="操作" width="150" align="center">
					    <template slot-scope="scope">
					        <el-button type="text" icon="el-icon-edit" @click="handleQuestionEdit(scope.$index, scope.row, 2)">编辑</el-button>
					        <el-button type="text" icon="el-icon-delete" class="red" @click="handleQuestionDelete(scope.$index, scope.row)">删除</el-button>
					    </template>
					</el-table-column>
				</el-table>
				<div class="pagination">
				    <el-pagination background @current-change="handleCurrentChangeQuestion" layout="prev, pager, next" :total="question_table_sumPage">
				    </el-pagination>
				</div>
			</el-dialog>
			
			<!-- 添加试题 -->
			<el-dialog title="添加试题" v-loading="loading" :visible.sync="questionEditVisible" width="40%">
				<el-form  :model="questionForm" label-width="145px">
					<el-form-item label="问卷选择" prop="survey_id">
					<el-select v-model="questionForm.survey_id" placeholder="请选择问卷">
					    <el-option
					            v-for="item in selectSurveyList"
					            :key="item.id"
					            :label="item.title"
					            :value="item.id">{{item.title}}
					    </el-option>
					</el-select>
					</el-form-item>
					<el-form-item label="标题" prop="title">
						<el-input v-model="questionForm.title" placeholder="请输入问题标题"></el-input>
					</el-form-item>
					<el-form-item label="选项" prop="">
						<div v-for="(item, index) in questionItems" :key="index" style="padding: 10px;">
							<span>选项{{index+1}}</span>
							<el-input v-model="questionItems[index]" placeholder="" style="width:200px;padding-left:10px;"></el-input>
						</div>
						<el-button type="primary" @click="addItems()">添加</el-button>
					</el-form-item>
					<el-form-item label="类型" prop="">
						<el-radio v-model="questionForm.type"  label="0">单选</el-radio>
						<el-radio v-model="questionForm.type"  label="1">多选</el-radio>
					</el-form-item>
					<el-form-item label="模式" prop="complex">
						<el-radio v-model="questionForm.complex"  label="0">默认</el-radio>
						<el-radio v-model="questionForm.complex"  label="1">多行文本</el-radio>
						<el-radio v-model="questionForm.complex"  label="2">单行文本</el-radio>
						<!-- <el-radio v-model="questionForm.complex"  label="3">都有</el-radio> -->
					</el-form-item>
					<el-form-item label="状态" prop="status">
						<el-radio v-model="questionForm.status"  label="0">禁用</el-radio>
						<el-radio v-model="questionForm.status"  label="1">启用</el-radio>
					</el-form-item>
					<el-form-item label="排序" prop="sort">
						<el-input v-model="questionForm.sort" placeholder=""></el-input>
						<span>越小越靠前</span>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
				    <el-button @click="questionEditVisible = false">取 消</el-button>
				    <el-button type="primary" @click="saveQuestionEdit()">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 删除提示框 -->
			<el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
			    <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
			    <span slot="footer" class="dialog-footer">
			        <el-button @click="delVisible = false">取 消</el-button>
			        <el-button type="primary" @click="deleteRow">确 定</el-button>
			    </span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	export default {
		 name: 'basetable',
		 data(){
			 return {
			     url: './vuetable.json',
			     tableData:[],
				 userTableData:[],
				 questionTableData:[],
			     cur_page: 1,  //当前页
			     number: 10,  //每页显示条数
			     sumPage:10,  //总页数
				 AddOrSave:'',  //1表示添加，2表示更新
				 user_table_cur_page:1,
				 user_table_sum_page:10,
				 question_table_cur_page:1,
				 question_table_sumPage:10,
				
			     multipleSelection: [],
				 editVisible: false,
				 delVisible: false,
				 userVisible:false,
				 questionVisible:false,	//问题列表
				 questionEditVisible:false,	//问题弹窗
				 selectSurveyList:[],
				 idx: -1,
				 loading:false, //加载中
				 title:'',
				 del_list:[],
				 form:{
					 id:'',
					 title:'',
					 status:'1',
				 },
				 
				questionItems:[''],
				questionForm:{
					 id:'',
					 title:'',
					 type:'0',
					 status:'1',
					 complex:'0',
					 survey_id:'',
					 items:[''],
					 sort:0
				 },
				 del_table:'survey',
				 rules:{
					 title:[
						  { required: true, message: '请输入标题', trigger: 'blur' }
					 ]
				 },
				 userDetail:[],
				 userDetailVisible:false
			}
		 },
		 created() {
		     //获取初始数据
		     this.getData();
		 },
		 computed: {
		     data() {
		       return  this.tableData.filter((d) => {
		           let is_del = false;
		           for (let i = 0; i < this.del_list.length; i++) {
		               if (d.title === this.del_list[i].title) {
		                   is_del = true;
		                   break;
		               }
		           }
				   return d;
		       })
		     }
		 },
		 methods: {
		     getData(){
				 var params=this.$qs.stringify({
				     pageIndex: this.cur_page,
				     number: this.number,
					 title:this.title
				 });
				 this.$api.post('ShopSurvey/getSurveyList', params, res => {
				     this.tableData = res.data.list;
				     this.sumPage = res.data.sumPage*10;
				     this.cur_page = res.data.currentPage;
				     console.log(res);
				 }, err => {
				     this.tableData = [];
				     this.sumPage = 1*10;
				     this.cur_page = 1;
				     this.$message.error(err.msg);
				 });
			 },
			 getUserData() {
			     //不传参数要写null
			     var params=this.$qs.stringify({
			         pageIndex: this.user_table_cur_page,
			         number: this.number
			     });
			     this.$api.post('ShopSurveyUser/index', params, res => {
			         this.userTableData = res.data.list;
			         this.user_table_sum_page = res.data.sumPage*10;
			         this.user_table_cur_page = res.data.currentPage;
			     }, err => {
			         this.tableData = [];
			         this.user_table_sum_page = 1*10;
			         this.user_table_cur_page = 1;
			         this.$message.error(err.msg);
			     });
				 this.userVisible = true
			 },
			 getQuestionData(){		//试题列表
				this.questionVisible = true;
				 var params=this.$qs.stringify({
				     pageIndex: this.question_table_cur_page,
				     number: this.number
				 });
				 this.$api.post('ShopSurveyQuestion/index', params, res => {
				     this.questionTableData = res.data.list;
				     this.question_table_sumPage = res.data.sumPage*10;
				     this.question_table_cur_page = res.data.currentPage;
				 }, err => {
				     this.tableData = [];
				     this.question_table_sumPage = 1*10;
				     this.question_table_cur_page = 1;
				     this.$message.error(err.msg);
				 });
			 }, 
			 handleShow(row){		//参与用户详情
				 this.userDetailVisible =  true
				  var  params=this.$qs.stringify({
						id:row.id				
				  })
				  
				  this.$api.post('ShopSurveyUser/show',params,res=>{
					   this.userDetail = res.data;
				  },err =>{
					  this.userDetail = []
					  this.$message.error(err.msg);
				  })
			  },
			  handleEdit(index, row, status) {			//问卷
				   this.AddOrSave=status;
				   if(status == 1){
					   this.form = {
						id:null,
						title:null, 
						status:'1'
					   }
				   }
				   if(index!=undefined && row!=undefined){
					   this.idx = index;
					   const item = this.tableData[index];
					   this.form = {
						   id:item.id,
						   title:item.title,
						   status:item.status
					   }
				   }
				   this.editVisible = true;
			  },
			  handleQuestionEdit(index,row,status){		//试题
				  this.AddOrSave=status;
				  if(status == 1){
					  this.questionItems = []
					  this.questionForm = {
							id:null,
							title:null, 
							type:'0',
							status:'1',
							complex:'0',
							survey_id:'',
							items:[],
							sort:0
					  }
				  }
				  if(index!=undefined && row!=undefined){
					  this.idx = index;
					  const item = this.questionTableData[index];
					  console.log(item)
					  this.questionItems =  item.items
					  this.questionForm = item
				  }
				  this.$api.post('ShopSurveyQuestion/getSurveyList',null,res=>{
						this.selectSurveyList = res.data.list
				  },err=>{
						this.$message.error(err.msg);
				  })
				  this.questionEditVisible = true;
			  },
			  saveQuestionEdit(){		//添加问题
				  this.questionEditVisible = false
				  this.questionForm.items =  this.questionItems;
				 var params = this.$qs.stringify(this.questionForm)
				  this.$api.post('ShopSurveyQuestion/save',params,res=>{
						this.getData()
						this.$message.success(res.msg);
				  },err=>{
						this.$message.error(err.msg);
				  })
			  },
			  addItems(){
				  this.questionItems.push('')
			  },
			 
			 saveEdit(formName){		//保存问卷
				 this.$refs[formName].validate((valid) => {
					 this.editVisible = false;
					 if(valid){
						  var params=null;
						  if(this.AddOrSave==1){
							  params=this.$qs.stringify({
								  title:this.form.title,
								  status:this.form.status
							  })
						  }else{
							  params=this.$qs.stringify({
								  id:this.form.id,
								  title:this.form.title,
								  status:this.form.status
							  })
						  }
						  this.$api.post('ShopSurvey/save', params, res => {
						      this.getData();
						      this.$message.success(res.msg);
						      // console.log(res);
						  }, err => {
						      this.$message.error(err.msg);
						  });
					 }else{
						 // console.log("请填写所需数据")
						 return false;
					 }
				 })
			 },
			 handleDelete(index, row) {
				 this.del_table = 'survey'
			     this.idx = index;
			     this.form = row;
			     this.delVisible = true;
			 },
			 handleQuestionDelete(index,row){
				 this.del_table = 'question'
				 this.idx = index;
				 this.questionForm = row
				 this.delVisible = true
			 },
			 // 确定删除
			 deleteRow(){
				 var id  = ''
				 var url = ''
				 console.log(this.del_table)
				 if(this.del_table == 'survey'){
					 id = this.form.id
					 url  = 'ShopSurvey/delSurvey'; 
				 }else if( this.del_table  == 'question'){
					 id  = this.questionForm.id
					 url =  'ShopSurveyQuestion/del'
				 }
			     var params=this.$qs.stringify({
			         id: id
			     });
			     // return;
			     this.$api.post(url, params, res => {
					 if(this.del_table == 'survey'){
						 this.getData();
					 }else{
						 this.getQuestionData()
					 }
			         
			         this.$message.success(res.msg+res.data+"条数据");
			     }, err => {
			         this.$message.error(err.msg);
			     });
			     this.delVisible = false;
			 },
			 // 分页导航
			 handleCurrentChange(val) {
			     this.cur_page = val;
			     this.getData();
			 },
			 handleCurrentChangeQuestion(val){
				 this.question_table_cur_page  = val
				 this.getQuestionData()
			 },
			 handleCurrentChangeUser(val){
				 this.user_table_cur_page = val
				 this.getUserData()
			 }
		}
	}
</script>

<style scoped>
	.handle-box {
	    margin-bottom: 20px;
	}
	
	.handle-input {
	    width: 300px;
	    display: inline-block;
	}
</style>
